import {
  BankOutlined,
  UsergroupAddOutlined,
  SignalFilled,
} from "@ant-design/icons";
import { StepProps } from "antd";

export const steps: StepProps[] = [
  {
    title: "General information",
    icon: <BankOutlined />,
    status: "finish",
  },
  {
    title: "Members",
    icon: <UsergroupAddOutlined />,
    status: "finish",
  },
  {
    title: "Set Target Value",
    icon: <SignalFilled />,
    status: "finish",
  },
];

export const MaxLengthGroup = {
  MAX_GROUP_NAME_LENGTH: 20,
  MAX_DESCRIPTION_LENGTH: 200,
};

export const STATUS_GROUP = [
  { label: "Draft", id: "0" },
  { label: "Submitted", id: "1" },
  { label: "Approved", id: "3" },
  { label: "Rejected", id: "2" },
  { label: "Published", id: "4" },
];

export const PLATFORMS_LOG = [
  { label: "Mobile", id: "Mobile" },
  { label: "Web", id: "Web" },
];

